
.onfocus .video-play {
    /* min-height: 400px; */
  }
  
  .onfocus .content {
    /* background: linear-gradient(color-mix(in srgb, #ffffff, transparent 90%), color-mix(in srgb, #ffffff, transparent 75%)), url("https://img.freepik.com/free-vector/hand-painted-watercolor-nature-background_23-2148941599.jpg?t=st=1727856923~exp=1727860523~hmac=7db0775c97b686629c0ff11706818ac5870e08ba29314f4480105d588fccc3f1&w=740") center center; */
    background-size: cover;
    padding: 40px;
  }
  
  @media (min-width: 768px) {
    .onfocus .content {
      padding: 80px;
    }
  }
  
  .onfocus .content h3 {
    font-weight: 600;
    font-size: 32px;
  }
  
  .onfocus .content ul {
    list-style: none;
    padding: 0;
  }
  
  .onfocus .content ul li {
    padding-bottom: 10px;
  }
  
  .onfocus .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #0ea2bd;
  }
  
  .onfocus .content p:last-child {
    margin-bottom: 0;
  }
  
  .onfocus .content .read-more {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 12px 24px;
    border-radius: 5px;
    transition: 0.3s;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #0f928d;
  }
  
  .onfocus .content .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
  }
  
  .onfocus .content .read-more:hover {
    background: color-mix(in srgb,  #0ea2bd 90%, black 15%);
    padding-right: 19px;
  }
  
  .onfocus .content .read-more:hover i {
    margin-left: 10px;
  }
  
  .onfocus .pulsating-play-btn {
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
  }
  .onfocus .video-play{
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally (optional) */
  }
  /* .onfocus .video-play .framevideo{
   
      height: 70vh;
      width: 100%;
  } */
  @media (min-width: 768px) {
    .onfocus .video-play .framevideo {
      height: 70vh;
      width: 100%;
    }
  }
  @media (max-width:700px) {
    .onfocus .video-play .framevideo {
      height: auto;
      width: 100%;
    }
  }
  .onfocus .framevideo .ytp-cued-thumbnail-overlay-image{
    background-size: contain !important;
  }
    