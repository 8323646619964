.main-template-course-div .starter-template {
    padding: 40px 15px;
    text-align: center;
    }
    .main-template-course-div  .three-column-grid{
    text-align:center;
    }
    .main-template-course-div .three-column-grid ul{
        text-align: left;
    }
    .main-template-course-div{
        background: linear-gradient(color-mix(in srgb, #ffffff, transparent 90%), color-mix(in srgb, #ffffff, transparent 75%)), url("https://img.freepik.com/free-vector/hand-painted-watercolor-abstract-watercolor-background_23-2149018550.jpg?t=st=1727859672~exp=1727863272~hmac=36671d7d1a8de19ee35d94a4520998af59f5ecd1b044051bd4a508794abca6d7&w=740") center center;
        background-size: cover;
        background-repeat: no-repeat;
    }