.hero-bg-image img{
    position: relative;
    height: auto;
    width: 100%;
}
.hero-slider{
    /* ori-650*/
    height: 450px;
}
.hero-slide-item {
    height: 450px;
    /* background-color:#fac9b42b; */
    background-color: #fff;
}
.img-bg-shape img{
    /* background-color: #e5e5f7; */
    opacity: 1;
    /* background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient(to bottom right, rgba(246,186,152,1), rgba(255,255,255,1) 50% );  */
    /* background-image: repeating-linear-gradient(to top left, rgba(246,186,152,1) 40%, rgba(255,255,255,1) 90% );  */
    /* background-image: repeating-linear-gradient(to top left, rgba(246,186,152,1) 20%, rgba(246, 187, 152, 0.308) 30% );  */

    border-radius: 5%;
    /* background-size: 20%; */
    /* background-repeat: no-repeat; */
}
.bg-banner1{
    background-image: url('../assets/images/Banner-1.png');
    /* background-image: url('../assets/images/bg_banner_3.jpg'); */
  }
.bg-banner2{
    background-image: url('../assets/images/banner_2.webp');
    background-size: cover;
}  

  @media only screen and (min-width: 650px){
    .bg-banner-text{
      background-color: #194da10a;
      padding: 20px 65px;
      border-radius: 1%;
      margin-right: 5px;
      /* color: #ab1dce !important; */
    }
  }
  @media only screen and (max-width: 600px){
    .bg-banner-text{
      background-color: #194da10a;
      padding: 20px 18px;
      border-radius: 1%;
      /* color: #ab1dce !important; */
    }
  }
  .hero-slide-content .title-1{
    /* color: #4CF224 !important; */
  }